/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { TableContext } from "../../Context/TableContext";
import { selectAlphabeticalListForDesktop, selectdownlaodLists, selectErrorWhileFetchingTables, selectIsFetchingTables } from "../../Selectors";
import { StyledSeatingPlanContainer, StyledCenteredContainer, StyledHeading } from "../../Styles";
import { AlphabeticalList } from "./AlphabeticalList";
import { useSearchParams } from "react-router-dom";
import { Theme } from "../../Theme";
import { EventContext } from "../../Context/EventContext";
import {
  selectEventImage,
  selectEventName,
  selectFetchOneEventFailure,
  selectFontFamilyGuest,
  selectFontFamilyLetterHeading,
  selectFontFamilyTableNumber,
  selectFontFamilyTitle,
} from "../../Selectors/eventSelectors";
import { Info } from "../Ui/Info";
import { isDesktop } from "react-device-detect";
import { LoadingText } from "./LoadingText";
import { BurgerMenu } from "./BurgerMenu";

export const SeatingPlan = () => {
  const { fetchTables, state } = useContext(TableContext);
  const { state: eventState, fetchOneEvent } = useContext(EventContext);
  const eventName = selectEventName(eventState);
  const eventImageUrl = selectEventImage(eventState);
  const fontFamilyLetterHeading = selectFontFamilyLetterHeading(eventState);
  const fontFamilyGuest = selectFontFamilyGuest(eventState);
  const fontFamilyTitle = selectFontFamilyTitle(eventState);
  const fontFamilyTableNumber = selectFontFamilyTableNumber(eventState);
  const eventInfo = { eventImageUrl, eventName, fontFamilyGuest, fontFamilyLetterHeading, fontFamilyTableNumber, fontFamilyTitle };
  const isFetchingTables = selectIsFetchingTables(state);
  const fetchTablesFailure = selectErrorWhileFetchingTables(state);
  const fetchOneEventFailure = selectFetchOneEventFailure(eventState);
  const fetchingError = fetchTablesFailure || fetchOneEventFailure;
  let listToShow;
  if (isDesktop) {
    listToShow = selectAlphabeticalListForDesktop(state);
  } else {
    const { alphabeticalList } = selectdownlaodLists(state);
    listToShow = alphabeticalList;
  }
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const noGuestsAssigned = listToShow.length === 0;
  const showLoading = isFetchingTables || isFetchingTables === undefined || eventState.isFetchingEvent;
  const [menuOpen, setMenuOpen] = useState(false);
  const handleStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const onLetterClick = () => {
    setMenuOpen((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    if (id) {
      fetchTables({ eventId: id });
      fetchOneEvent(id);
    }
  }, [id, fetchTables]);

  if (!id) {
    return (
      <StyledCenteredContainer>
        <StyledHeading color={Theme.colors.red}>No event id found in the url.</StyledHeading>
      </StyledCenteredContainer>
    );
  }

  if (showLoading) {
    return <LoadingText />;
  }

  if (fetchingError) {
    return (
      <StyledCenteredContainer>
        <StyledHeading color={Theme.colors.red}>{fetchingError}</StyledHeading>
      </StyledCenteredContainer>
    );
  }
  if (noGuestsAssigned) {
    return (
      <StyledCenteredContainer>
        <Info
          text={"There are no guests assigned to any of the tables."}
          textStyle="regular"
          iconStyle={{ width: 60, height: 60, color: Theme.colors.darkBlue, marginRight: 10 }}
          containerStyle={{}}
        />
      </StyledCenteredContainer>
    );
  }
  return (
    <>
      <BurgerMenu isOpen={menuOpen} onStateChange={handleStateChange} onLetterClick={onLetterClick} />
      <StyledSeatingPlanContainer>
        <AlphabeticalList alphabeticalList={listToShow} autoFillSpace="300px" textStyle="regular" eventInfo={eventInfo} />
      </StyledSeatingPlanContainer>
    </>
  );
};
