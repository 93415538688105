import { createSelector } from "reselect";
import { IEventReducerState } from "../Context/EventReducer";
import { isMobile, isTablet } from "react-device-detect";
const selectEventState = (state: IEventReducerState) => state;

export const selectEventName: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.name || "My Event";
});

export const selectFontFamilyGuest: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.fontFamilyGuest || "Prata";
});
export const selectFontFamilyLetterHeading: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.fontFamilyLetterHeading || "Prata";
});
export const selectFontFamilyTitle: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.fontFamilyTitle || "Prata";
});
export const selectFontFamilyTableNumber: (state: IEventReducerState) => string = createSelector(selectEventState, (state) => {
  return state.event?.fontFamilyTableNumber || "Prata";
});

export const selectAllFonts: (state: IEventReducerState) => string[] = createSelector(selectEventState, (state) => {
  return [
    state.event?.fontFamilyTableNumber || "Prata",
    state.event?.fontFamilyLetterHeading || "Prata",
    state.event?.fontFamilyGuest || "Prata",
    state.event?.fontFamilyTitle || "Prata",
  ];
});

export const selectShouldShowSaveFontsButton: (state: IEventReducerState, initialFonts: string[]) => boolean = createSelector(
  [selectAllFonts, (state, initialFonts: string[]) => initialFonts],
  (fonts, initialFonts) => {
    for (let i = 0; i < initialFonts.length; i++) {
      if (initialFonts[i] !== fonts[i]) {
        return true;
      }
    }
    return false;
  }
);

export const selectEventImage: (state: IEventReducerState) => string | undefined = createSelector(selectEventState, (state) => {
  if (isMobile && state.event?.mobileImageUrl) {
    return state.event.mobileImageUrl;
  }
  if (isTablet && state.event?.tabletImageUrl) {
    return state.event.tabletImageUrl;
  }
  return state.event?.imageUrl;
});

const selectFetchOneEventFailureState = (state: IEventReducerState) => state.fetchOneEventFailure;

export const selectFetchOneEventFailure: (state: IEventReducerState) => string | undefined = createSelector(
  [selectFetchOneEventFailureState],
  (error) => {
    return error;
  }
);
