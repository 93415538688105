import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { TextStyles, Theme } from "../../Theme";
import { AlphabeticalLetter } from "../DownloadSeatingPlan/AlphabeticalLetter";
import { selectAlphabeticalListForDesktop } from "../../Selectors";
import { TableContext } from "../../Context/TableContext";
import { EventContext } from "../../Context/EventContext";
import { StyledText } from "../../Styles";
import { Button } from "../Ui";
import {
  selectFontFamilyGuest,
  selectFontFamilyLetterHeading,
  selectFontFamilyTableNumber,
  selectFontFamilyTitle,
  selectShouldShowSaveFontsButton,
} from "../../Selectors/eventSelectors";
import FontPicker from "./CustomFonts/FontPicker";
import { useAddEventFonts } from "../../CustomHooks/useAddEventFonts";
import { EventName } from "./EventName";

const Container = styled.div`
  gap: 5%;
  display: flex;
  align-content: "flex-end";
  flex-wrap: wrap;
  padding: 1.5rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  ${TextStyles.regular}
`;

const HelpText = styled.div`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #6b7280;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  ${TextStyles.regular}
`;

const PreviewContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  background: white;
  margin-bottom: 20px;
`;

export const CustomiseFonts: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [fontPickerOpen, setFontPickerOpen] = useState<string | null>();

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isInsideFontPicker = target.closest("[data-fontpicker]");

      if (!isInsideFontPicker) {
        setFontPickerOpen(null);
      }
    };

    document.addEventListener("mousedown", handleGlobalClick);
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  const { state } = useContext(TableContext);
  const { changeEventFontFamily, state: eventState } = useContext(EventContext);
  const { addEventFonts } = useAddEventFonts();

  const fontFamilyLetterHeading = selectFontFamilyLetterHeading(eventState);
  const fontFamilyGuest = selectFontFamilyGuest(eventState);
  const fontFamilyTableNumber = selectFontFamilyTableNumber(eventState);
  const fontFamilyTitle = selectFontFamilyTitle(eventState);

  const list = selectAlphabeticalListForDesktop(state);
  const showFontPreview = list.length > 0;
  const initialFonts = useMemo(
    () => [fontFamilyTableNumber, fontFamilyLetterHeading, fontFamilyGuest, fontFamilyTitle],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventState.isSavingEventFont]
  );
  const showSaveButton = showFontPreview && selectShouldShowSaveFontsButton(eventState, initialFonts);
  return (
    <div>
      <StyledText className="apply-font" textStyle="heading_2" color={Theme.colors.darkBlue} fontWeight="500" margin="0px 0px 7px 0px">
        Seating Plan Fonts
      </StyledText>
      <Container ref={containerRef}>
        <div style={{ flex: 1 }}>
          <Section>
            <Label>Event Title</Label>
            <FontPicker
              isOpen={fontPickerOpen === "title"}
              initialFont={fontFamilyTitle}
              setIsOpen={() => {
                setFontPickerOpen(fontPickerOpen === "title" ? null : "title");
              }}
              onFontSelect={(fontFamily: string) => {
                changeEventFontFamily("fontFamilyTitle", fontFamily);
              }}
              pickerId="title"
            />
          </Section>
          <Section>
            <Label>Letter Heading</Label>
            <FontPicker
              isOpen={fontPickerOpen === "letter"}
              initialFont={fontFamilyLetterHeading}
              setIsOpen={() => {
                setFontPickerOpen(fontPickerOpen === "letter" ? null : "letter");
              }}
              onFontSelect={(fontFamily: string) => {
                changeEventFontFamily("fontFamilyLetterHeading", fontFamily);
              }}
              pickerId="letter"
            />
          </Section>
          <Section>
            <Label>Guest Name</Label>
            <FontPicker
              isOpen={fontPickerOpen === "name"}
              initialFont={fontFamilyGuest}
              setIsOpen={() => {
                setFontPickerOpen(fontPickerOpen === "name" ? null : "name");
              }}
              onFontSelect={(fontFamily: string) => {
                changeEventFontFamily("fontFamilyGuest", fontFamily);
              }}
              pickerId="name"
            />
          </Section>
          <Section>
            <Label>Table Number</Label>
            <FontPicker
              initialFont={fontFamilyTableNumber}
              isOpen={fontPickerOpen === "number"}
              setIsOpen={() => {
                setFontPickerOpen(fontPickerOpen === "number" ? null : "number");
              }}
              onFontSelect={(fontFamily: string) => {
                changeEventFontFamily("fontFamilyTableNumber", fontFamily);
              }}
              pickerId="tableNumber"
            />
          </Section>
        </div>
        <div>
          {showFontPreview && (
            <PreviewContainer>
              <EventName />
              <AlphabeticalLetter
                letterAndList={{ letter: list[0].letter, list: list[0].list.slice(0, 1) }}
                key={"123"}
                fontColor={Theme.colors.black}
                fontFamilyLetterHeading={fontFamilyLetterHeading}
                fontFamilyGuest={fontFamilyGuest}
                fontFamilyTableNumber={fontFamilyTableNumber}
                fontSize="38px"
                backgroundColor={"transparent"}
                hasLinkId={true}
                nameWidth="190px"
              />
            </PreviewContainer>
          )}
          {!showFontPreview && (
            <HelpText>
              No preview available. <br /> <br />
              Assign guests to tables before previewing the font.
            </HelpText>
          )}

          {showSaveButton && (
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button
                text={"Save font changes"}
                isLoading={eventState.isSavingEventFont}
                onPress={addEventFonts}
                spinnerColor={Theme.colors.darkBlue}
                buttonStyles={{
                  padding: "10px",
                  color: Theme.colors.darkBlue,
                  borderRadius: "10px",
                  backgroundColor: Theme.colors.white,
                  border: `1px solid ${Theme.colors.darkBlue}`,
                }}
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
