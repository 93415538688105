import { createContext, useReducer } from "react";
import {
  ADD_EVENT_NAME_FAILURE,
  ADD_EVENT_NAME_LOADING,
  ADD_EVENT_NAME_SUCCESS,
  CHANGE_EVENT_FONT,
  FETCH_ONE_EVENT_FAILURE,
  FETCH_ONE_EVENT_LOADING,
  FETCH_ONE_EVENT_SUCCESS,
  IEventReducerState,
  IMAGE_REMOVE_SUCCESS,
  IMAGE_UPLOAD_SUCCESS,
  SAVE_EVENT_FONT_FAILURE,
  SAVE_EVENT_FONT_LOADING,
  SAVE_EVENT_FONT_SUCCESS,
  TCustomEventFont,
  eventReducer,
} from "./EventReducer";
import { addEventFonts, addEventName as addEventNameInDB, getEvent } from "../Firebase/firestore";
import { logger } from "../Logger";
import {
  selectFontFamilyGuest,
  selectFontFamilyLetterHeading,
  selectFontFamilyTableNumber,
  selectFontFamilyTitle,
} from "../Selectors/eventSelectors";
interface IEventContext {
  state: IEventReducerState;
  addEventName(eventName: string, eventId: string): Promise<void>;
  changeEventFontFamily(fontKey: TCustomEventFont, font?: string): void;
  saveEventFonts(eventId: string): Promise<void>;
  onEventImageAdded(imageUrl: string): void;
  onEventImageRemoved(): void;
  fetchOneEvent(eventId: string): Promise<void>;
}
const initialState: IEventReducerState = {
  isAddingEvent: false,
  isFetchingEvent: true,
  isSavingEventFont: false,
};
export const EventContext = createContext<IEventContext>({
  state: initialState,
  changeEventFontFamily: () => {
    throw new Error("method not initialized");
  },
  addEventName: () => {
    throw new Error("method not initialized");
  },
  onEventImageAdded: () => {
    throw new Error("method not initialized");
  },
  onEventImageRemoved: () => {
    throw new Error("method not initialized");
  },
  fetchOneEvent: () => {
    throw new Error("method not initialized");
  },
  saveEventFonts: () => {
    throw new Error("method not initialized");
  },
});

export const EventProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(eventReducer, initialState);

  const addEventName = async (eventName: string, eventId: string) => {
    try {
      dispatch({ type: ADD_EVENT_NAME_LOADING });
      await addEventNameInDB(eventName, eventId);
      dispatch({ type: ADD_EVENT_NAME_SUCCESS, payload: { eventName } });
    } catch (e) {
      dispatch({ type: ADD_EVENT_NAME_FAILURE });
    }
  };

  const fetchOneEvent = async (eventId: string) => {
    try {
      logger.warn("fetching event");
      dispatch({ type: FETCH_ONE_EVENT_LOADING });
      const event = await getEvent(eventId);
      dispatch({ type: FETCH_ONE_EVENT_SUCCESS, payload: { event } });
    } catch (e) {
      logger.error(e);
      const error = "An Error occurred while fetching the guest list. Please reload the page.";
      dispatch({ type: FETCH_ONE_EVENT_FAILURE, error });
    }
  };

  const changeEventFontFamily = (fontKey: TCustomEventFont, font: string) => {
    dispatch({ type: CHANGE_EVENT_FONT, payload: { font, fontKey } });
  };

  const saveEventFonts = async (eventId: string) => {
    try {
      if (!state.event) {
        return;
      }
      const fontFamilyGuest = selectFontFamilyGuest(state);
      const fontFamilyTableNumber = selectFontFamilyTableNumber(state);
      const fontFamilyLetterHeading = selectFontFamilyLetterHeading(state);
      const fontFamilyTitle = selectFontFamilyTitle(state);
      dispatch({ type: SAVE_EVENT_FONT_LOADING });
      await addEventFonts({ fontFamilyGuest, fontFamilyTableNumber, fontFamilyLetterHeading, fontFamilyTitle }, eventId);
      dispatch({ type: SAVE_EVENT_FONT_SUCCESS });
    } catch (e) {
      logger.error(e);
      dispatch({ type: SAVE_EVENT_FONT_FAILURE });
    }
  };

  const onEventImageAdded = (imageUrl: string) => {
    dispatch({ type: IMAGE_UPLOAD_SUCCESS, imageUrl });
  };
  const onEventImageRemoved = () => {
    dispatch({ type: IMAGE_REMOVE_SUCCESS });
  };

  return (
    <EventContext.Provider
      value={{
        state,
        addEventName,
        fetchOneEvent,
        onEventImageAdded,
        onEventImageRemoved,
        changeEventFontFamily,
        saveEventFonts,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
