import { useContext } from "react";
import { StyledEventDetailsContainer } from "../../Styles/EventDetails.styled";
import { EventLink } from "./EventLink";
import { EventQRCode } from "./EventQRCode";
import { EventStats } from "./EventStats";
import { StyledCenteredContainer, StyledMarginContainer, StyledSpinner, StyledText } from "../../Styles";
import { Theme } from "../../Theme";
import { TableContext } from "../../Context/TableContext";
import { ExportEvent } from "./ExportEvent";
import { EventContext } from "../../Context/EventContext";
import { CustomiseFonts } from "./CustomiseFonts";

export const EventDetails = () => {
  const {
    state: { fetchTablesLoading },
  } = useContext(TableContext);
  const {
    state: { isFetchingEvent },
  } = useContext(EventContext);
  const margin = "0px 0px 70px 0px";
  if (isFetchingEvent || fetchTablesLoading) {
    return (
      <StyledCenteredContainer>
        <StyledSpinner size="60px" color={Theme.colors.darkBlue} />
      </StyledCenteredContainer>
    );
  }

  return (
    <StyledEventDetailsContainer>
      <div style={{ width: "50%" }}>
        <StyledMarginContainer margin={"0px 0px 10px 0px"}>
          <StyledText textStyle="heading_2" color={Theme.colors.black} fontWeight="500" lineHeight="1.5">
            Event Details
          </StyledText>
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <EventStats />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <ExportEvent />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <EventLink />
        </StyledMarginContainer>
        <StyledMarginContainer margin={margin}>
          <EventQRCode />
        </StyledMarginContainer>
      </div>
      <div style={{ flex: 1, maxWidth: 650 }}>
        <CustomiseFonts />
      </div>
    </StyledEventDetailsContainer>
  );
};
