import { AlignColumnItemsCenter, StyledRowContainer, StyledText } from "../Styles";
import { Theme } from "../Theme";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button } from "./Ui";
import { useCheckout } from "../CustomHooks/useCheckout";

interface ISellingPointProps {
  mainText: string;
  explanation: string;
}
const SellingPoint = ({ mainText, explanation }: ISellingPointProps) => {
  return (
    <StyledRowContainer width="400px" margin="0px 0px 15px 0px">
      <AiFillCheckCircle style={{ color: Theme.colors.darkBlue, width: 25, height: 25, marginRight: 15, flexShrink: 0, alignSelf: "flex-start" }} />
      <div>
        <StyledText textStyle="regular" textAlign="left">
          {mainText}
        </StyledText>
        <StyledText textStyle="regular" textAlign="justify" lineHeight="18px" color={Theme.colors.mediumGrey} margin="5px 0px 0px 0px">
          {explanation}
        </StyledText>
      </div>
    </StyledRowContainer>
  );
};

const sellingPoints = [
  {
    mainText: "Innovative Digital Seating Plan",
    explanation: "Share a personalized link or a QR code for your event. Guests can quickly scan or click to see their seating arrangement.",
  },
  {
    mainText: "Real-Time Seating Plan Updates",
    explanation:
      "Update your seating plan in real time. Any changes sync instantly with your digital plan, ensuring up-to-date and accurate event details.",
  },
  {
    mainText: "Seamless Export to CSV and Excel",
    explanation: "Easily convert your seating arrangement into a shareable Excel file for efficient coordination with your venue and caterers.",
  },
  {
    mainText: "Intuitive Drag-and-Drop Interface",
    explanation: "Effortlessly upload your guest list from a CSV or Excel file, and simply drag them to their designated tables.",
  },
  {
    mainText: "Advanced Search Capabilities",
    explanation: "Quickly locate any guest with our powerful search feature.",
  },
];

export const PayWall = () => {
  const { createCheckoutSession, loading, error } = useCheckout();

  return (
    <AlignColumnItemsCenter>
      <StyledText textStyle="regular" color={Theme.colors.darkBlue} margin="20px 0px 25px 0px" width="400px" textAlign="center" lineHeight="1.5">
        You do not have any uploads remaining in your free account. Unlock unlimited uploads for a one-time fee of $9.99
      </StyledText>
      {sellingPoints.map((point, index) => {
        return <SellingPoint mainText={point.mainText} explanation={point.explanation} key={index} />;
      })}
      {error && (
        <StyledText textStyle={"regular"} margin="0px 0px 15px 0px" color="red" width="400px" textAlign="center">
          {error}
        </StyledText>
      )}
      <Button
        text={"Checkout"}
        isLoading={loading}
        buttonStyles={{ height: "37px", width: "50%", borderRadius: "10px", color: Theme.colors.white }}
        onPress={createCheckoutSession}
      />
    </AlignColumnItemsCenter>
  );
};
