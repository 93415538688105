import { FieldProps } from "formik";
import {
  StyledInputField,
  StyledTextAreaField,
  StyledInputFieldAndErrorDisplayContainer,
  StyledInputError,
  IOverrideTextInputStyles,
  StyledInputFieldLabel,
} from "../Styles";

type TOwnProps = {
  overrideStyles?: IOverrideTextInputStyles;
  type?: string;
  disabled?: boolean;
  error?: string;
  touched?: boolean;
  min?: number;
  isDuplicate?: boolean;
  placeholder?: string;
  label?: string;
  autoComplete?: string;
  multiline?: boolean;
  numberOfLines?: number;
  onChange?(event: React.ChangeEvent<any>): void;
};

export type TTextInputFieldProps = TOwnProps & Partial<FieldProps>;

export const TextInputField = ({
  overrideStyles = {},
  type,
  min,
  placeholder,
  label,
  autoComplete,
  disabled,
  multiline = false,
  numberOfLines = 3,
  ...props
}: TTextInputFieldProps) => {
  const showError = (props.touched && props.error) || props.error === "Duplicate";
  let inputFieldStyle = overrideStyles.inputFieldStyle;
  if (disabled && overrideStyles && overrideStyles.inputFieldStyle) {
    inputFieldStyle = { ...overrideStyles.inputFieldStyle, backgroundColor: "#e6e6e6" };
  }

  // Handle key down event to prevent form submission on Enter in multiline mode
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (multiline && event.key === "Enter") {
      event.stopPropagation();
    }
  };

  return (
    <StyledInputFieldAndErrorDisplayContainer inputFieldAndErrorDisplayContainerStyle={overrideStyles.inputFieldAndErrorDisplayContainerStyle}>
      {label && <StyledInputFieldLabel>{label}</StyledInputFieldLabel>}

      {multiline ? (
        <StyledTextAreaField
          inputFieldStyle={inputFieldStyle}
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          rows={numberOfLines}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <StyledInputField
          inputFieldStyle={inputFieldStyle}
          type={type}
          {...props}
          min={min}
          placeholder={placeholder}
          autoComplete={autoComplete || "off"}
          disabled={disabled}
        />
      )}

      {showError && <StyledInputError inputErrorStyle={overrideStyles.inputErrorStyle}>{props.error}</StyledInputError>}
    </StyledInputFieldAndErrorDisplayContainer>
  );
};
