import React from "react";

export const EventTitle = ({ title }: { title: string }) => {
  return (
    <>
      {title.split("\n").map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < title.split("\n").length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};
