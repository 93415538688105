import styled from "styled-components";
import { TextStyles } from "../Theme";
import { TTextInputFieldProps } from "../Components";
import ReactTooltip from "react-tooltip";

type TForm = {
  form?: any;
};

interface IOverrideStyles {
  width?: string;
  marginRight?: string;
  height?: string;
  focusOutlineStyle?: string;
  flexBasis?: string;
  padding?: string;
  marginBottom?: string;
  marginTop?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  display?: string;
  resize?: string;
  fontFamily?: string;
}
export interface IOverrideInputStyles {
  overrideStyles?: IOverrideStyles;
}

export interface IOverrideTextInputStyles {
  inputFieldStyle?: IOverrideStyles;
  inputFieldAndErrorDisplayContainerStyle?: IOverrideStyles;
  inputErrorStyle?: IOverrideStyles;
}

type TStyledTextInputFieldProps = TTextInputFieldProps & TForm & IOverrideTextInputStyles;

export const StyledInputField = styled.input<TStyledTextInputFieldProps>`
  ${TextStyles.regular}
  background-color: ${({ theme, inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.backgroundColor) || theme.colors.mediumLightGrey};
  border-color: ${({ theme, error, touched }) => (error && touched ? theme.colors.red : "rgba(180, 180, 180, 0.25)")};
  width: ${({ inputFieldStyle }) => {
    return (inputFieldStyle && inputFieldStyle.width) || "100%";
  }};
  height: ${({ inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.height) || "80%"};
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
  &:focus {
    outline: ${({ inputFieldStyle, error }) => (!error && inputFieldStyle && inputFieldStyle.focusOutlineStyle) || "none"};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  font-size: ${({ inputFieldStyle }) => inputFieldStyle && inputFieldStyle.fontSize};
  font-weight: 400;
`;

export const StyledTextAreaField = styled.textarea<TStyledTextInputFieldProps>`
  ${TextStyles.regular}
  background-color: ${({ theme, inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.backgroundColor) || theme.colors.mediumLightGrey};
  border-color: ${({ theme, error, touched }) => (error && touched ? theme.colors.red : "rgba(180, 180, 180, 0.25)")};
  width: ${({ inputFieldStyle }) => {
    return (inputFieldStyle && inputFieldStyle.width) || "100%";
  }};
  height: ${({ inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.height) || "80%"};
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
  resize: ${({ inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.resize) || "none"};
  font-family: ${({ inputFieldStyle }) => (inputFieldStyle && inputFieldStyle.fontFamily) || "inherit"};
  &:focus {
    outline: ${({ inputFieldStyle, error }) => (!error && inputFieldStyle && inputFieldStyle.focusOutlineStyle) || "none"};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  font-size: ${({ inputFieldStyle }) => inputFieldStyle && inputFieldStyle.fontSize};
  font-weight: 400;
`;

export const StyledTableInputHeading = styled.div<IOverrideInputStyles>`
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
`;

export const StyledAddNewTableRowButton = styled.button<IOverrideInputStyles>`
  ${TextStyles.regular}
  border-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  width: 100%;
  margin: auto;
  border-width: 1px;
  border-style: dashed;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    opacity: 0.5;
  }
`;

export const StyledOverflowContainer = styled.div<IOverrideInputStyles>`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.mediumGrey};
    border-radius: 10px;
  }
`;

export const StyledInputFieldAndErrorDisplayContainer = styled.div<TStyledTextInputFieldProps>`
  flex-direction: column;
  display: ${({ inputFieldAndErrorDisplayContainerStyle }) => inputFieldAndErrorDisplayContainerStyle?.display ?? "flex"};
  width: ${({ inputFieldAndErrorDisplayContainerStyle }) => inputFieldAndErrorDisplayContainerStyle && inputFieldAndErrorDisplayContainerStyle.width};
  margin-top: ${({ inputFieldAndErrorDisplayContainerStyle }) =>
    inputFieldAndErrorDisplayContainerStyle && inputFieldAndErrorDisplayContainerStyle.marginTop};
  margin-right: ${({ inputFieldAndErrorDisplayContainerStyle }) =>
    inputFieldAndErrorDisplayContainerStyle && inputFieldAndErrorDisplayContainerStyle.marginRight};
`;

export const StyledInputError = styled.div<TStyledTextInputFieldProps>`
  ${TextStyles.regular};
  color: ${({ theme }) => theme.colors.red};
  padding: ${({ inputErrorStyle }) => inputErrorStyle?.padding};
  margin-top: ${({ inputErrorStyle }) => inputErrorStyle?.marginTop};
  font-size: ${({ inputErrorStyle }) => inputErrorStyle?.fontSize};
`;

export const StyledQuestionMarkContainer = styled.a`
  margin-left: 5px;
`;

export const StyledReactToolTip = styled(ReactTooltip)`
  ${TextStyles.regular}/* font-size: 10px !important; */
`;

export const StyledGridContainer = styled.div`
  display: grid;
  max-height: 256px;
  grid-template-columns: 2fr 2fr 3fr 0.5fr;
  grid-template-rows: 65px;
`;

export const StyledColumnHeadingsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 3.5fr;
`;

export const StyledSaveTableButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const StyledInputFieldLabel = styled.label`
  ${TextStyles.regular}
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-bottom: 8px;
`;
